$mainFont: 'AzoSans';

/* LIGHT */
@font-face {
    font-family: 'AzoSans';
    font-style: normal;
    font-weight: 300;
    src: url('/images/fonts/AzoSansWeb-Light.eot');/* IE9 Compat Modes */
    src: local(''),
        url('/images/fonts/AzoSansWeb-Light.eot?#iefix') format('embedded-opentype'),/* IE6-IE8 */
        url('/images/fonts/AzoSansWeb-Light.woff') format('woff'),/* Modern Browsers */
        url('/images/fonts/AzoSansWeb-Light.ttf') format('truetype'),/* Safari, Android, iOS */
        url('/images/fonts/AzoSansWeb-Light.svg#AzoSans') format('svg');/* Legacy iOS */
}

/* REGULAR */

@font-face {
    font-family: 'AzoSans';
    font-style: normal;
    font-weight: 400;
    src: url('/images/fonts/AzoSansWeb-Regular.eot');/* IE9 Compat Modes */
    src: local(''),
        url('/images/fonts/AzoSansWeb-Regular.eot?#iefix') format('embedded-opentype'),/* IE6-IE8 */
        url('/images/fonts/AzoSansWeb-Regular.woff') format('woff'),/* Modern Browsers */
        url('/images/fonts/AzoSansWeb-Regular.ttf') format('truetype'),/* Safari, Android, iOS */
        url('/images/fonts/AzoSansWeb-Regular.svg#AzoSans') format('svg');/* Legacy iOS */
}
/* BOLD */
@font-face {
    font-family: 'AzoSans';
    font-style: normal;
    font-weight: 700;
    src: url('/images/fonts/AzoSansWeb-Bold.eot');/* IE9 Compat Modes */
    src: local(''),
        url('/images/fonts/AzoSansWeb-Bold.eot?#iefix') format('embedded-opentype'),/* IE6-IE8 */
        url('/images/fonts/AzoSansWeb-Bold.woff') format('woff'),/* Modern Browsers */
        url('/images/fonts/AzoSansWeb-Bold.ttf') format('truetype'),/* Safari, Android, iOS */
        url('/images/fonts/AzoSansWeb-Bold.svg#AzoSans') format('svg');/* Legacy iOS */
}