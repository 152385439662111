@import '../../styles/defaults';

.welcome-page {
  min-height: calc(100vh - 130px);
  padding: 40px 20px 0 20px;

  background-image: url("/data/images/welcome-background.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;

  .content-title {
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 800px) {
      padding: 0;
    }

    .welcome__title- {
      &first {
        margin: 0;
        color: #4F60D2;
        font-size: 20px;
        font-weight: 700;
      }
      &second {
        padding: 10px;
        margin: 0;
        background-color: #BAD80A;
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
      }
      &third {
        margin: 0;
        color: #4F60D2;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .content-description {
    margin-top: 10px;

    p {
      margin: 5px 0px;
    }
  }

  .ant-col {
    padding: 0 40px;
  }

  .welcome-info {
    margin-top: 60px;

    .info-item {
      margin-top: 30px;

      .ant-col {
        padding: 0 !important;
  
        p {
          margin: 0;
          padding-left: 30px;
        }
      }
    }
  }
}

/* On screens that are 992px or less */
@media screen and (max-width: 768px) {
  .welcome-info {
    img {
      margin: auto 45%;
    }

    p {
      margin-top: 20px !important;
    }

    .welcome__title-third {
      margin-top: 50px;
    }
  }
}
