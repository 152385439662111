
// Agenda

$schedule-date-bg: #bad80a;

$schedule-date-bg-hover: #000000;

$schedule-page-background: #ffffff;

$schedule-page-title-color: #ffffff;

$schedule-speaker: #bad80a;

$schedule-time-color: #333333;

$schedule-title: #4f60d2;

$speaker-desc: #000000;

// Caixa de Perguntas

$player-qa-submit-bg: #bad80a;

$player-qa-submit-bg-hover: #bad80a;

$question-box-border: #d9d9d9;

$question-box-list-border: #b5b5b5;

// Chat

$chat-box-border: #d9d9d9;

$player-chat-scroll-color: #393939;

$player-chat-submit-bg: #bad80a;

$player-chat-submit-bg-hover: #bad80a;

// Cookies

$cookies-background: #999999;

$cookies-btn-bg: #ff0000;

$cookies-btn-bg-hover: #000000;

// Geral

$color-highlight: #ff0000;

$footer-background: #bad80a;

$footer-color: #ffffff;

$footer-link-color: #ffffff;

$footer-link-color-over: #333333;

$menu-selected: #bad80a;

$player-btn-off-bg: #bad80a;

$player-btn-on-bg: #bad80a;

$player-btn-text-off: #4f60d2;

$player-btn-text-on: #ffffff;

$primary-btn-bg: #ff0000;

$primary-btn-bg-hvr: #d10000;

$primary-color: #4f60d2;

$primary-dark-text-color: #333333;

$primary-text-color: #ffffff;

$primary-text-color-hvr: #d1d1d1;

$secondary-text-color: #333333;

// Homepage

$home-button-border: #4bdbc5;

$home-countdown-background: #bad80a;

$home-countdown-bordersmoke: #4f60d2;

$home-countdown-text: #ffffff;

$home-countdown-unit: #ffffff;

$home-event-date: #ffffff;

$home-page-text: #ffffff;

$home-page-title: #ffffff;

// Live Poll

$live-poll-h3: #4f60d2;

$live-poll-h6: #8c8c8c;

$live-poll-icon-votes-color: #000000;

$live-poll-option-background-color: #ebebeb;

$live-poll-option-checked-background-color: #4f60d2;

$live-poll-option-checked-text-color: #ffffff;

$live-poll-option-text-color: #ffffff;

// Oradores

$speaker-photo: #aaaaaa;

$speakers-bg: transparent;

$speakers-description-color: #000000;

$speakers-name-color: #ffffff;

$speakers-title-color: #000000;

// Pagina Login / Registo

$login-btn-bg: #4f60d2;

$login-btn-bg-hvr: #4f60d2;

$login-btn-color: #ffffff;

$login-input-bg: #c7c7c7;

$login-input-placeholder-color: #707070;

$register-btn-bg: #9b9b9b;

$register-btn-bg-hvr: #757575;

$register-btn-color: #ffffff;

// Pagina do Evento

$player-chat-questions-bg: #000000;

$player-chat-questions-color: #ffffff;

$player-icon-votes-color: #ffffff;

$player-submit-background: #bad80a;

$player-submit-background-hover: #bad80a;

$player-submit-color: #4f60d2;

$player-submit-color-hover: #4f60d2;

$player-submit-placeholder-color: #ffffff;

$player-title-color: #6ea514;

